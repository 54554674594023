export const dataLayerPush = (layer) => {
    if (typeof window === 'undefined') {
        return;
    }

    window._mtm = window._mtm || [];
    window._mtm.push(layer);
};

export function trackEvent(event, category, action, label, dimensions = {}) {
    dataLayerPush({
        event,
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        ...dimensions,
    });
}

export function paqTrackEvent(category, action, label) {
    if (typeof window === 'undefined') {
        return;
    }
    window._paq = window._paq || [];
    window._paq.push(['trackEvent', category, action, label]);
}

export function trackBanner(level, category, headline, extraData = {}) {
    if (typeof level !== 'number') {
        return;
    }

    let bannerCategory = category;

    if (Array.isArray(category)) {
        bannerCategory = category.join(',');
    }

    dataLayerPush({
        event: 'bannerClick',
        eventCategory: 'Startpage',
        eventAction: 'Banner click',
        bannerLevel: level,
        bannerCategory: bannerCategory,
        bannerHeadline: headline,
        ...extraData,
    });
}

export function trackCtaClickStartpage() {
    dataLayerPush({
        event: 'ctaClickStartpage',
    });
}
